// @ts-check

// 언어가 추가되는 변수입니다.
/** @type {import('./localize').SupportedLocalesObject} */
const SUPPORTED_LOCALES_OBJ = {
  ko: 'ko',
  en: 'en',
  ja: 'ja',
  id: 'id',
  th: 'th',
  fil: 'fil',
  vi: 'vi',
  ms: 'ms',
  uz: 'uz',
  fr: 'fr',
  km: 'km',
  ru: 'ru',
  'zh-CN': 'zh-CN',
  'zh-TW': 'zh-TW',
};
/** @type {import('./localize').SupportedLocalesArray} */
const SUPPORTED_LOCALES_ARR = Object.values(SUPPORTED_LOCALES_OBJ);

module.exports = {
  SUPPORTED_LOCALES_OBJ,
  SUPPORTED_LOCALES_ARR,
};

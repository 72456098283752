import type { SVGProps } from 'react';
export const ChartPieFill = (props: SVGProps<SVGSVGElement>) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="ChartPieFill" data-sentry-source-file="ChartPieFill.tsx">
      <g id="Chart-pie-fill" data-sentry-element="g" data-sentry-source-file="ChartPieFill.tsx">
        <g id="Icon" data-sentry-element="g" data-sentry-source-file="ChartPieFill.tsx">
          <path d="M8.23566 1.83901C8.63205 1.7923 9.03145 1.89701 9.35393 2.13218C9.67641 2.36736 9.89817 2.71565 9.97483 3.10734L9.99566 3.24651L9.99983 3.33318V9.16651C9.99986 9.37062 10.0748 9.56763 10.2104 9.72015C10.3461 9.87268 10.533 9.97013 10.7357 9.99401L10.8332 9.99984H16.4998C16.9419 9.99984 17.3658 10.1754 17.6783 10.488C17.9909 10.8006 18.1665 11.2245 18.1665 11.6665C18.1665 11.7299 18.1592 11.7931 18.1448 11.8548C17.8113 13.2937 17.1014 14.6181 16.0877 15.6924C15.0741 16.7667 13.7931 17.5522 12.3759 17.9686C10.9588 18.385 9.4565 18.4173 8.02282 18.0621C6.58913 17.7069 5.2756 16.9771 4.21675 15.9473C3.15789 14.9175 2.39176 13.6248 1.99681 12.2016C1.60185 10.7784 1.59227 9.27571 1.96904 7.84755C2.34582 6.41938 3.0954 5.11702 4.14104 4.07383C5.18668 3.03064 6.49079 2.2841 7.91983 1.91068L8.14483 1.85484L8.2365 1.83818L8.23566 1.83901Z" fill="#7A7B89" data-sentry-element="path" data-sentry-source-file="ChartPieFill.tsx" />
          <path d="M11.6665 2.91652V7.49985C11.6665 7.72087 11.7543 7.93283 11.9106 8.08911C12.0669 8.24539 12.2788 8.33319 12.4998 8.33319H17.0832C17.2164 8.33315 17.3476 8.30118 17.4659 8.23997C17.5842 8.17875 17.6861 8.09008 17.7631 7.98136C17.84 7.87265 17.8898 7.74707 17.9083 7.61516C17.9267 7.48324 17.9132 7.34883 17.869 7.22319C17.4551 6.04763 16.7828 4.97988 15.9017 4.09855C15.0205 3.21722 13.9528 2.54476 12.7773 2.13069C12.6516 2.0863 12.5171 2.07271 12.3851 2.09106C12.2531 2.10941 12.1274 2.15916 12.0186 2.23615C11.9097 2.31313 11.821 2.41509 11.7597 2.53348C11.6985 2.65187 11.6665 2.78322 11.6665 2.91652Z" fill="#7A7B89" data-sentry-element="path" data-sentry-source-file="ChartPieFill.tsx" />
        </g>
      </g>
    </svg>;
};
import NiceModal from '@ebay/nice-modal-react';
import { ProChip } from '@zep/components/chip';
import { NameEditModal } from '@zep/components/modals/NameEditModal';
import { useUser } from '@zep/lib/auth';
import { Button } from '@zep/ui';
import { cn, combinePathAndQuery } from '@zep/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { MyMenuDropdown, trackGnbAnalytics } from './';
type HeaderButtonProps = {
  type: 'drawer' | 'normal';
  className?: string;
};
export const AuthButton = () => {
  const {
    user,
    isInitialization
  } = useUser();
  if (!isInitialization) {
    return null;
  }
  if (user) {
    return <MyMenuDropdown className="hidden md:flex" />;
  } else {
    return <LogInButton type="normal" className="hidden md:flex" />;
  }
};
export const LogInButton = ({
  type,
  className
}: HeaderButtonProps) => {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const loginLink = combinePathAndQuery('/login', router.query);
  if (type === 'drawer') {
    return <Button intent={'neutral'} transParented asChild className={cn('text-[15px] flex gap-3xs justify-start h-[48px]')}>
        <Link href={loginLink} onClick={() => trackGnbAnalytics('login', 'Header')}>
          <img src="/assets/icon_login.svg" alt="login-icon" />
          {t('header.login', '로그인')}
        </Link>
      </Button>;
  }
  return <Button size={'md'} transParented intent={'neutral'} asChild className={className} data-sentry-element="Button" data-sentry-component="LogInButton" data-sentry-source-file="UserManagementButton.tsx">
      <Link href={loginLink} onClick={() => trackGnbAnalytics('login', 'Header')} data-sentry-element="Link" data-sentry-source-file="UserManagementButton.tsx">
        {t('header.login')}
      </Link>
    </Button>;
};
export const LogOutButton = ({
  isDrawerOpen
}: {
  isDrawerOpen?: boolean;
}) => {
  const {
    t
  } = useTranslation();
  const {
    logOut
  } = useUser();
  if (isDrawerOpen) {
    return <Button intent={'neutral'} transParented size={'xs'} onClick={() => {
      logOut({
        redirectTo: '/landing'
      });
      trackGnbAnalytics('logout', 'Header');
    }}>
        {t('header.logout')}
      </Button>;
  }
  return <Button onClick={() => {
    logOut({
      redirectTo: '/landing'
    });
    trackGnbAnalytics('logout', 'Header');
  }} data-sentry-element="Button" data-sentry-component="LogOutButton" data-sentry-source-file="UserManagementButton.tsx">
      <img src="/assets/icon_out_red.svg" alt="logout" />{' '}
      <div className="text-[#FF4848]">{t('header.logout')}</div>
    </Button>;
};
export const EditNicknameMbButton = () => {
  const {
    user
  } = useUser();
  const handleNameEditModalOpen = () => {
    NiceModal.show(NameEditModal, {
      initialName: user?.username
    });
  };
  return <Button className={cn('hover:bg-transparent gap-xs bg-transparent items-center justify-center', 'max-w-[200px] overflow-hidden truncate px-0 py-[12.5px] text-[15px]')} onClick={handleNameEditModalOpen} data-sentry-element="Button" data-sentry-component="EditNicknameMbButton" data-sentry-source-file="UserManagementButton.tsx">
      <ProChip id={'3'} isShow={user?.planType === 'PRO'} data-sentry-element="ProChip" data-sentry-source-file="UserManagementButton.tsx" />
      <span className="max-w-full truncate text-default">{user?.username}</span>
      <img src="/assets/icon_pen_gray_line.svg" className="size-[18px]" alt="edit" />
    </Button>;
};
export const CreateNewQuizButton = ({
  type
}: HeaderButtonProps) => {
  const {
    t
  } = useTranslation();
  const {
    user
  } = useUser();
  if (type === 'drawer') {
    return <Button className={'w-full'} intent={'alertNative'} asChild>
        <Link href="/build" onClick={() => trackGnbAnalytics('create', 'Header', user?.username || '')}>
          <img src="/assets/icon_plus_primary.png" className="size-[24px]" alt="plusicon" />
          {/* TODO: 퀴즈 만들기 */}
          {t('header.create.button')}
        </Link>
      </Button>;
  }
  return <Button size={'md'} asChild data-sentry-element="Button" data-sentry-component="CreateNewQuizButton" data-sentry-source-file="UserManagementButton.tsx">
      <Link href="/build" onClick={() => trackGnbAnalytics('create', 'Header', user?.username || '')} className="flex gap-3xs px-xs py-[10px]" data-sentry-element="Link" data-sentry-source-file="UserManagementButton.tsx">
        <img src="/assets/icon_plus.png" className="size-[18px]" alt="plusicon" />
        {t('header.create.button')}
      </Link>
    </Button>;
};